import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import styles from './style.module.css';

let Slogan = () =>{
    let {works_section, quote, service} = styles;
    return(
        <Row className="justify-content-center align-items-center" id={works_section}>
            <Col className="py-5 my-5">
                <Container>
                    <Row className="justify-content-center align-items-center">
                        <Col className="text-center">
                            <h2>You name it, we got it, no work is too big.</h2>
                            <a href="#contacts" className={`mt-3 mb-5 btn-lg btn btn-light rounded-pill ${quote}`} role="button">Get a quote. Contact us!</a>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md="10">
                            <Row className="justify-content-center">
                                <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mb-2 mx-md-1 ${service}`}>
                                    <h5>Tree Care Maintenance</h5>
                                    <p>Our certified tree crews are trained in ornamental pruning and maintaining your trees health. Our team can Safely trim and prune while keeping your property safe and reduce liability risk.</p>
                                </Col>
                                <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mb-2 mx-md-1 ${service}`}>
                                    <h5>Tree Removal</h5>
                                    <p>Tree removal is difficult and dangerous work. We safely remove trees with trained technicians without endangering people or dangerous surroundings property.</p>
                                </Col>
                                <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mb-2 mx-md-1 ${service}`}>
                                    <h5>24/7 Storms Work and Hazard Tree Removal</h5>
                                    <p>We have a dedicated team of certified experts that are committed to bringing you peace of mind when it comes to all your tree removal needs.</p>
                                </Col>
                                <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mb-2 mx-md-1 ${service}`}>
                                    <h5>Utility Tree Work</h5>
                                    <p>Jordan Tree Service also providing professional services to utility companies. Our team of line clearance tree trimmer & hazard tree removal experts, experience and safety is our number one goal.</p>
                                </Col>
                                <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mb-2 mx-md-1 ${service}`}>
                                    <h5>Tree Trimming & Clean Up</h5>
                                    <p>Trees trimmed without a lot of residual mess, other than your trimmed trees, you won’t be able to tell we were even there with our thorough clean up services. We are here to exceed any tree trimming or clean up needs for your need.</p>
                                </Col>
                                <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mb-2 mx-md-1 ${service}`}>
                                    <h5>Fire Suppression Services</h5>
                                    <p>When it comes to fire prevention and fuel reduction, we have worked with the USFS and CAL Fire. We have great qualified team. Any emergency our team is here to for your service.</p>
                                </Col>
                                <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mb-2 mx-md-1 ${service}`}>
                                    <h5>City Work</h5>
                                    <p>We provide many city work services including crane services, track lifts, debris hauling, air spade services and much more! No city work is too big for our team, give us a call today and see Jordan Tree Service can provide.</p>
                                </Col>
                                <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mb-2 mx-md-1 ${service}`}>
                                    <h5>Landscape Maintenance</h5>
                                    <p>As you manage your property you face balancing the visual impact of your property against the cost of sustaining and protecting your investment. Our partnership ensures your landscape needs are covered from maintenance, fertilization, tree care, weed and, storm preparedness and response and exterior upkeep services.</p>
                                </Col>
                                <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mb-2 mx-md-1 ${service}`}>
                                    <h5>Soft Washing and Pressure Washing</h5>
                                    <p>We are perfectly located to deliver our wide range of Soft Washing and Pressure Washing services to residential, commercial, multi-unit, and industrial. From roof and house soft washing to building and concrete cleaning and even window cleaning and solar panel cleaning.</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="justify-content-center align-items-center" xs="12" md="10">
                        <Col className="text-center">
                            <a href="#contacts" className={`mt-5 btn-lg btn btn-light rounded-pill ${quote}`} role="button">Get a quote. Contact us!</a>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    )
};

export default Slogan;