import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import avetta from '../assets/images/avetta.logo.png';
import isn from '../assets/images/isn.logo.png';
let Logos = () => {
    return(
        <Row className="row py-4">
            <Col>
                <Container>
                    <Row className="align-items-center justify-content-center text-center ">
                        <Col className='col-auto'>
                            <img src={avetta} alt="Avetta"/>
                        </Col>
                        <Col className='col-auto'>
                            <img src={isn} alt="ISN"/>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    )
}

export default Logos;