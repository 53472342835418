import React,{useEffect,  useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo_img from '../logo_w.png';
import logo_g from '../logo.png';
import styles from './style.module.css';

let MainNav = () => {
    let {logo, nav_bar, navbarScroll, navbarScrollGreen} = styles;
    let [changeLogo,setChangeLogo] = useState(true);

    let obCallback = (payload) => {
        setChangeLogo(payload[0].isIntersecting);
    }
   
    useEffect(()=>{
        
        const watch = document.querySelector('#home');
        const ob = new IntersectionObserver(obCallback);
        ob.observe(watch);
        
    },[]);

    return(
        <Navbar expand="lg" fixed="top" id={navbarScroll}>
            <Container>
                <Navbar.Brand href="#home" className="header">
                    <img src={changeLogo ? logo_img : logo_g} alt='Jordan Tree Services' className={`${logo} sticky top-0`} />
                </Navbar.Brand>
                <Navbar.Toggle  aria-controls="navbarScroll"/>
                <Navbar.Collapse className='justify-content-end'>
                    <Nav id={changeLogo ? nav_bar : navbarScrollGreen}>
                        <Nav.Link href="#home">Home</Nav.Link>
                        <Nav.Link href="#about">GET TO KNOW JTS</Nav.Link>
                        <Nav.Link href="#services">OUR SERVICES</Nav.Link>
                        <Nav.Link href="#gallery">GALLERY</Nav.Link>
                        <Nav.Link href="#joinus">JOIN US</Nav.Link>
                        <Nav.Link href="#contacts">Contact Us</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>
    )
}

export default MainNav;