import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './style.module.css';
import cutting from '../assets/images/cutting-tree.png';
import planting from '../assets/images/planting-tree.png';
import crane from '../assets/images/crane_truck.png';
import tractor from '../assets/images/tractor.png';
import grapple from '../assets/images/grapple.png';
import skidsteer from '../assets/images/skidsteer.png';
import bucket from '../assets/images/bucket-truck.png';
import safety from '../assets/images/safety.png';
import pi_team from '../assets/images/pi-team.png';
import foreman from '../assets/images/foreman.png';

let Services=()=>{
    let {services_section, service} = styles;
    return(
        <>
            <span id="services"></span>
            <Row className="my-5 py-5 justify-content-center align-items-center" id={services_section}>
                <Col className="py-5 my-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="10">
                                <Row className="justify-content-center align-items-center pb-4">
                                    <Col md="9">
                                        <h2 className="text-center mb-4">OUR SERVICES</h2>
                                        <p>When it comes to <strong>Tree Care</strong>, consulting, and expert designs, no one does it better than <strong>Jordan Tree Service</strong>. We provide full <strong>Tree Care Developers</strong>, <strong>Contractors</strong>, and <strong>Local Municipalities for Industrial</strong>, <strong>Commercial and Retail improvements</strong>. Storms cleanup, Hurricane cleanup any emergency work.</p>

    <p>Professional Tree care will not only add beauty and usability to your property, but it can also potentially add significant value and future marketability for your business.</p>
    <p>Also, we provide service to all the states for storms, Hurricanes or any emergency work needed.</p>

                                        <p>California Areas we are working within 100 miles, Sacramento, Stockton, Lodi, Acampo, Galt, Manteca, Modesto, Tracy, West Sacramento, El Dorado Hills</p>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center align-items-start">
                                        <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mb-2 mx-md-1 ${service}`}>
                                            <img src={bucket} alt="Bucket Crew" className="img-fluid w-50"/>
                                            <h5>Bucket Crew</h5>
                                        </Col>
                                        <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mb-2 mx-md-1 ${service}`}>
                                            <img src={cutting} alt="Climb Crew" className="img-fluid w-50"/>
                                            <h5>Climb Crew</h5>
                                        </Col>
                                        <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mb-2 mx-md-1 ${service}`}>
                                            <img src={planting} alt="Ground Crew" className="img-fluid w-50"/>
                                            <h5>Ground Crew</h5>
                                        </Col>
                                        <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mb-2 mx-md-1 ${service}`}>
                                            <img src={grapple} alt="Grapple Crew" className="img-fluid w-50"/>
                                            <h5>Grapple Crew</h5>
                                        </Col>
                                        <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mb-2 mx-md-1 ${service}`}>
                                            <img src={crane} alt="Crane Crew" className="img-fluid w-50"/>
                                            <h5>Crane Crew</h5>
                                        </Col>
                                        <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mb-2 mx-md-1 ${service}`}>
                                            <img src={skidsteer} alt="Skid Steer Crew" className="img-fluid w-50"/>
                                            <h5>Skid Steer Crew</h5>
                                        </Col>
                                        <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mb-2 text-center mx-md-1 ${service}`}>
                                            <img src={tractor} alt="Bobcat, Tractor & Grading Crew" className="img-fluid w-50"/>
                                            <h5>Bobcat, Tractor & Grading Crew</h5>
                                        </Col>
                                        <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mb-2 mx-md-1 ${service}`}>
                                            <img src={pi_team} alt="PI Team" className="img-fluid w-50"/>
                                            <h5>PI Team</h5>
                                        </Col>
                                        <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mx-md-1 ${service}`}>
                                            <img src={foreman} alt="GF Team" className="img-fluid w-25"/>
                                            <h5>GF Team</h5>
                                        </Col>
                                        <Col md="3" className={`d-flex flex-column rounded shadow-sm justify-content-center align-items-center mx-md-1 ${service}`}>
                                            <img src={safety} alt="Safety Team" className="img-fluid w-50"/>
                                            <h5>Safety Team</h5>
                                        </Col>
                                </Row>
                               
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </>
    )
}
export default Services;
