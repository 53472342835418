import React,{useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './style.module.css';
import image_01 from '../assets/images/gallery/image_01.jpeg';
import image_02 from '../assets/images/gallery/image_02.jpeg';
import image_03 from '../assets/images/gallery/image_03.jpeg';
import image_04 from '../assets/images/gallery/image_04.jpeg';
import image_05 from '../assets/images/gallery/image_05.jpeg';
import image_06 from '../assets/images/gallery/image_06.jpeg';
import image_08 from '../assets/images/gallery/image_08.jpeg';
import image_09 from '../assets/images/gallery/image_09.jpeg';
import image_10 from '../assets/images/gallery/image_10.jpeg';
import image_11 from '../assets/images/gallery/image_11.jpeg';
import image_12 from '../assets/images/gallery/image_12.jpeg';
import image_13 from '../assets/images/gallery/image_13.jpeg';
import image_14 from '../assets/images/gallery/image_14.jpeg';
import image_15 from '../assets/images/gallery/image_15.jpeg';
import image_16 from '../assets/images/gallery/image_16.jpeg';
import image_17 from '../assets/images/gallery/image_17.jpeg';
import image_18 from '../assets/images/gallery/image_18.jpeg';
import image_19 from '../assets/images/gallery/image_19.jpeg';
import image_20 from '../assets/images/gallery/image_20.jpeg';
import image_21 from '../assets/images/gallery/image_21.jpeg';
import image_22 from '../assets/images/gallery/image_22.jpeg';
import image_23 from '../assets/images/gallery/image_23.jpeg';
import image_24 from '../assets/images/gallery/image_24.jpeg';
import image_25 from '../assets/images/gallery/image_25.jpeg';
import image_26 from '../assets/images/gallery/image_26.jpeg';
import image_27 from '../assets/images/gallery/image_27.jpeg';

let Gallery = () => {

    let [active, setActive] = useState(false);
    let [image, setImage] = useState('');
    let {gallery_section,img_gallery, lightbox} = styles;

    let openLighbox = () => {
        return(
            <div className={[lightbox, active ? 'd-flex' : 'd-none'].join(' ') } onClick={toggleActive}>
                <img src={image} className="img-fluid img-thumbnail rounded shadow-lg" alt="Gallery"/>
            </div>
        )
    }

    let toggleActive = (img) => {
        setImage(img);
        setActive(!active);
    }

    return (
        <>
            <span id="gallery"></span>
            {active ? openLighbox() : null}
            <Row className="justify-content-center align-items-center py-5" id={gallery_section}>
                <Col className="py-5 my-5">
                    <Container>
                        <Row className="justify-content-center align-items-center">
                            <Col>
                                <h2 className="text-center">GALLERY</h2>
                            </Col>
                        </Row>
                        <Row className="py-4 justify-content-center">
                            <Col md="8">
                                <Row className="justify-content-center">
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_01)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_01})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div  onClick={()=>toggleActive(image_02)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_02})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div  onClick={()=>toggleActive(image_03)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_03})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_04)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_04})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_05)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_05})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_06)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_06})`}}></div>
                                    </Col>
                                    {/* <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_07)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_07})`}}></div>
                                    </Col> */}
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_08)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_08})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_09)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_09})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_10)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_10})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_11)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_11})`}}></div>
                                    </Col>
                                    
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_16)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_16})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_17)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_17})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_18)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_18})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_19)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_19})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_20)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_20})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_21)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_21})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_22)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_22})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_23)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_23})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_24)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_24})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_25)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_25})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_26)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_26})`}}></div>
                                    </Col>
                                    <Col md="3" className='mb-4'>
                                        <div onClick={()=>toggleActive(image_27)} className={`${img_gallery} rounded shadow-lg img-thumbnail h-100 w-100`} style={{backgroundImage:`url(${image_27})`}}></div>
                                    </Col>
                                    
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </>
    )
}

export default Gallery;