import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import trees from '../assets/images/trees_bg.png';
import jts from '../assets/images/jts_img.png';
import styles from './style.module.css';

let AboutUs = () =>{
    let {about_section} = styles;
    return(
        <>
            <span id="about">&nbsp;</span>
            <Row className="justify-content-center align-items-center" id={about_section}>
                <Col>
                    <Container>
                        <Row className="py-5 mt-sm-3 justify-content-center">
                            <Col md="10">
                                <Row  className="justify-content-center align-items-center">
                                    <Col lg="3">
                                        <img src={jts} alt="JTS" className="img-fluid" />
                                    </Col>
                                    <Col lg="9">
                                    <h2 className="mb-4">GET TO KNOW JTS</h2>
                                    <p>Jordan Tree Service provides a full Tree Care. Tree Care Division provides weekly maintenance services for Commercial, City, County properties and utility company. Our tree care division and installation crews offer services to both residential, commercial, and utility properties as well.</p>
                        
                                    <p>We pride ourselves on communication and partnership. At JTS, your overall experience our top priority. Our team is here to support your specific goals and property needs with focus on three key elements for success: Safety, Service, & Excellence.</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </>
    )
};

export default AboutUs;