import React,{useRef, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './style.module.css';
import emailjs from '@emailjs/browser';
import Dots from "react-activity/dist/Dots";
import "react-activity/dist/Dots.css";

let JoinUs = () => {
    let {join_us} = styles;
    let [send, setSend] = useState(false);
    let [sending, setSending] = useState(false);
    let contact_form = useRef();

    const sendEmail = (e) => {
        setSending(true);
        e.preventDefault();
    
        emailjs.sendForm('service_g7b6qzc', 'template_7jnxux8', contact_form.current, 'n4mRHHvtYePB06bAE')
          .then((result) => {
              console.log(result.text);
              setSend(true);
              setSending(false);
              document.getElementById("form").reset();
          }, (error) => {
              console.log(error.text);
              setSending(false);
          });
    };

    return(
        <>
            <span id="joinus"></span>
            <Row className="justify-content-center align-items-center" id={join_us}>
                <Col>
                    <Container>
                        <Row className="pt-5 justify-content-center align-items-center">
                            <Col md="8">
                                <h2 className="text-center mb-3">Join Our Team and Grow with Us!</h2>
                                <p>Looking for a rewarding career with opportunities for growth? Whether your dream office is somewhere in the great outdoors or something more traditional, we have a career path for you.</p><p> We believe in providing a healthy, growing work-environment where you choose your career path and have access to constant education and the tools you need to set yourself up for continued success.</p>

                                <p>With jobs available throughout State, we have an opportunity for you in the location you desire.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row className="justify-content-center align-items-center">
                                    <Col md="8">
                                        <h3 className="mb-4">Please fill the next form</h3>
                                        <form className="g-3" ref={contact_form} onSubmit={sendEmail} id="form">
                                            <div className="mb-3">
                                                {/* <label for="fullname" class="form-label">Name:</label> */}
                                                <input type="text" name="name" className="form-control rounded-0" id="name" placeholder="Your name" required/>
                                            </div>
                                            <div className="mb-3">
                                                {/* <label for="email" class="form-label">Email:</label> */}
                                                <input type="email" name="email" className="form-control rounded-0" id="email" placeholder="Your email" required/>
                                            </div>
                                            <div className="mb-3">
                                                {/* <label for="phone" class="form-label">Phone:</label> */}
                                                <input type="tel" name="phone" className="form-control rounded-0" id="phone" placeholder="Your phone number" required/>
                                            </div>
                                            <div className="mb-3">
                                                {/* <label for="aboutYouTextarea1" class="form-label">About you</label> */}
                                                <textarea required className="form-control rounded-0" id="message" rows="3" name="message" placeholder="Tell us about you"></textarea>
                                            </div>
                                            <div className="col-auto">
                                                {sending ? <Dots /> : <button type="submit" className="btn btn-secondary mb-3  rounded-0">Send</button>}
                                            </div>
                                        </form>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center align-items-center mb-4">
                                    <Col className="text-center">
                                        {send ? <p style={{color:'green',fontSize:'18px'}}>Congratulations your message was sent!</p> : null}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            
        </>
    )
}

export default JoinUs;