import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Slider01 from '../assets/images/gallery/image_01.jpeg';
import Slider02 from '../assets/images/gallery/image_02.jpeg';
import Slider03 from '../assets/images/gallery/image_05.jpeg';
import styles from './style.module.css';

let SliderPrincipal =({children, title}) => {
    let {slider, active_slider, caption} = styles;
    return(
        <>
        <div id="message" fixed="top" style={{position:'fixed',height:'100px',zIndex:99999}} ></div>
        <Carousel id="home" className={`${slider} hv-100`}>
            <Carousel.Item style={{backgroundImage:`url(${Slider01})`}} className={`${active_slider} hv-100`}>
                <Carousel.Caption className={caption}>
                    <h3>SAFETY - SERVICE - EXCELLENCE</h3>
                    <p>Certified IQCS, TCIA, OSHA, WFPP, WLF, SMOC, ISA, ISnetworld.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item style={{backgroundImage:`url(${Slider02})`}} className={`${active_slider} hv-100`}>
                <Carousel.Caption className={caption}>
                    <h3>SAFETY - SERVICE - EXCELLENCE</h3>
                    <p>Certified IQCS, TCIA, OSHA, WFPP, WLF, SMOC, ISA, ISnetworld.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item style={{backgroundImage:`url(${Slider03})`}} className={`${active_slider} hv-100`}>
                <Carousel.Caption className={caption}>
                    <h3>SAFETY - SERVICE - EXCELLENCE</h3>
                    <p>Certified IQCS, TCIA, OSHA, WFPP, WLF, SMOC, ISA, ISnetworld.</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
        </>
    )
}

export default SliderPrincipal;