import Container from 'react-bootstrap/Container';
import MainNav from './commons/MainNav';
import SliderPrincipal from './commons/SliderPrincipa';
import AboutUs from './commons/AboutUs';
import Services from './commons/Services';
import Slogan from './commons/Slogan';
import Gallery from './commons/Gallery';
import JoinUs from './commons/JoinUs';
import ContactUs from './commons/ContactUs';
import Logos from './commons/Logos';
import Footer from './commons/Footer';
import './App.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAB1dWidIdbj7QiAHnYrL04F3HrupMfacQ",
  authDomain: "jordan-tree-services.firebaseapp.com",
  projectId: "jordan-tree-services",
  storageBucket: "jordan-tree-services.appspot.com",
  messagingSenderId: "996104920579",
  appId: "1:996104920579:web:c51089bffd3a368280b836",
  measurementId: "G-FRB92J5M3N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <>
      <MainNav/>
      <SliderPrincipal/>
      <Container fluid>      
        <AboutUs/>
        <Services/>
        <Slogan/>
        <Gallery/>
        <JoinUs/>
        <ContactUs/>
        <Logos/>
      </Container>
      <Footer/>
    </>
     
  );
}

export default App;
