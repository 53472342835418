import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from './style.module.css';

let Footer = () => {
    let {footer} = Styles;
    return(

        <footer id={footer} className="container-fluid py-4">
            <Col>
                <Container >
                    <Row className="align-items-center justify-content-between text-center ">
                        <Col className="col-12 col-md-6 d-flex justify-content-start">
                            <p className="m-0 p-0">&copy;&reg; All rights reserved 2024 - Jordan Tree Services</p>
                            
                        </Col>
                        <Col className="col-12 col-md-6 d-flex justify-content-end">
                            <p className="m-0 p-0">by <a href="http://calcano.dev" target="_blank" rel="noreferrer">Calcano.dev</a></p>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </footer>
    )
};

export default Footer;